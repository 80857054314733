import { Button } from "@fluentui/react-components";
import { t } from "i18next";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import AreaSelect from "../../controls/areaSelect/areaSelect";
import { excelHelper } from "../../services/excel.helper";
import { utilsHelper } from "../../services/utilsHelper";

const CellMerge = () =>{ 
    const mergeCells = async () =>{
        await excelHelper.mergeCells();
    }

    return(
        <>
            <Button onClick={mergeCells}>MergeCells</Button> 
        </>
    )
}

export default withTranslation()(CellMerge);